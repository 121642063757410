import { ResponseNS } from "../components/interfaces";
import { UserLoginParamsType } from "../components/types";
import { FORMPAY_API_BASE_URL } from "../constants";
import { fetchRequest } from "./helpers";
import { defaultGetOptions, defaultPostOptions } from "./requestOptions";

export class UserService {
  #endpoint = `${FORMPAY_API_BASE_URL}/users`;
  #userToken: string | null;

  constructor(userToken: string | null) {
    this.#userToken = userToken;
  }

  getUser() {
    const fetchOptions = {
      ...defaultGetOptions,
      headers: {
        ...defaultGetOptions.headers,
        Authorization: `Bearer ${this.#userToken}`,
      },
    };
    const response = fetchRequest(this.currentUserEndpoint, fetchOptions);
    return response;
  }

  async login(payload: UserLoginParamsType): Promise<ResponseNS.IUserResponse> {
    const fetchOptions = {
      ...defaultPostOptions,
      body: JSON.stringify(payload),
      headers: {
        ...defaultPostOptions.headers,
        Authorization: `Bearer ${this.#userToken}`,
      },
    };

    const response = await fetchRequest(this.loginUserEndpoint, fetchOptions);
    return response;
  }

  logout() {
    const fetchOptions = {
      ...defaultPostOptions,
      headers: {
        ...defaultPostOptions.headers,
        Authorization: `Bearer ${this.#userToken}`,
      },
    };

    const response = fetchRequest(this.logoutUserEndpoint, fetchOptions);
    return response;
  }

  get currentUserEndpoint() {
    return `${this.#endpoint}/current`;
  }

  get loginUserEndpoint() {
    return `${this.#endpoint}/login`;
  }

  get logoutUserEndpoint() {
    return `${this.#endpoint}/logout`;
  }
}
